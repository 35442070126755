<template>
  <div class="os-video" :class="{ 'has-loaded': hasLoaded }">
    <video
      v-if="!hasError"
      id="videoContainer"
      ref="videoContainer"
      :key="url"
      :aria-label="description"
      :poster="poster"
      playsinline
      :loop="loop"
      :muted="muted"
      :autoplay="autoplay"
      :preload="preload"
      class="os-video__container"
      @error="onError"
      @loadstart="onLoading"
      @loadeddata="onLoaded"
    >
      <track kind="captions" />
      <source
        v-if="url && contentType"
        :src="url"
        :type="contentType"
        @error="onError"
      />
    </video>
    <div v-else>Error loading video</div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'VideoPlayer',
  props: {
    poster: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    contentType: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true
    },
    controls: {
      type: Boolean,
      required: false,
      default: false
    },
    loop: {
      type: Boolean,
      required: false,
      default: true
    },
    muted: {
      type: Boolean,
      required: false,
      default: true
    },
    preload: {
      type: String,
      required: false,
      default: 'auto'
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props: { url: any; contentType: any }) {
    const hasError = ref<boolean>(false)
    const hasLoaded = ref<boolean>(false)
    const videoContainer = ref<null | HTMLVideoElement>(null)

    const onError = () => {
      hasLoaded.value = false
      hasError.value = true
    }

    const onLoaded = () => {
      hasLoaded.value = true
      hasError.value = false
      videoContainer.value?.play()
    }

    const onLoading = () => {
      hasLoaded.value = false
      hasError.value = false
    }

    // trigger error if url or contentType is not present
    if (!props.url || !props.contentType) {
      onError()
    }

    // ensure video reloads itself on network failure if url changes on resize
    watch(
      () => props.url,
      () => {
        onLoading()
      }
    )

    onMounted(() => {
      useIntersectionObserver(
        videoContainer,
        ([{ isIntersecting }]) => {
          isIntersecting
            ? videoContainer.value?.play()
            : videoContainer.value?.pause()
        },
        {
          threshold: 0.25
        }
      )

      if (videoContainer.value) {
        videoContainer.value.load()
      }
    })

    return {
      onError,
      onLoaded,
      onLoading,
      hasError,
      hasLoaded,
      videoContainer
    }
  }
}
</script>

<style lang="scss" scoped>
.os-video {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  pointer-events: none;
  width: 100%;

  background: url('~/assets/images/star-bg-desktop.jpg') no-repeat center center;
  background-size: cover;
  aspect-ratio: 16/9;
  &.has-loaded {
    background: none;
    aspect-ratio: unset;
  }
}

.os-video__container {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
</style>
