<template>
  <section
    v-if="isInViewport"
    ref="promoOffer"
    class="promoOffer"
    :class="{
      'promoOffer--hasImage': hasImage
    }"
  >
    <div
      class="promoOffer__container"
      :class="{
        'promoOffer__container--isShown': isInViewport
      }"
    >
      <div class="promoOffer__container__copy">
        <h2>{{ data.body }}</h2>
        <RichTextRenderer
          name="promoOffer"
          :content="data.termsAndConditionsCtaRichText"
        />
      </div>
      <div
        v-if="data.primaryCtaUrl && !isCurrentFinanceOffer"
        class="promoOffer__container__button"
      >
        <a
          v-if="data.primaryCtaOpenInNewTab"
          class="os-button os-button--primary"
          :href="data.primaryCtaUrl"
          target="_blank"
          rel="noopener"
          @click="track"
        >
          {{ data.primaryCtaText }}
        </a>
        <NuxtLink
          v-else
          class="os-button os-button--primary"
          :to="data.primaryCtaUrl"
          @click="applyFilters()"
        >
          {{ data.primaryCtaText }}
        </NuxtLink>
      </div>
      <div v-if="hasImage" class="promoOffer__container__image">
        <picture>
          <source
            media="(min-width: 992px)"
            :srcset="data.promotionImages?.desktopImage?.file.url"
          />
          <source
            media="(min-width: 600px)"
            :srcset="data.promotionImages?.tabletImage?.file.url"
          />
          <img
            :src="data.promotionImages?.mobileImage?.file.url"
            :alt="data.body"
          />
        </picture>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { IParams } from '~/types/filters'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import { CmsPromotionalOffer } from '~/types/cms/contentModel'
import { ERouteConditions } from '~/types/routes'
import { ECondition } from '~/types/vehicle'

export default {
  props: {
    data: {
      type: Object as PropType<CmsPromotionalOffer>,
      required: true
    }
  },

  setup(props) {
    const promoOffer = ref<null | HTMLElement>(null)
    const financeCriteriaStore = useFinanceCriteriaStore()
    const isInViewport = ref<boolean>(false)

    const route = useRoute()
    const { $dataLayer } = useNuxtApp()

    const applyFilters = () => {
      const condition: ECondition = convertCondition.fromConditonRouteParam(
        route.params.condition as ERouteConditions
      )

      // Fetch filters from URL
      const filters = filterCollection.fromQueryParams(
        route.query as unknown as IParams,
        condition
      )

      const { saveFilters } = useFilters(filters)

      saveFilters()

      track()
    }

    onMounted(() => {
      const { stop } = useIntersectionObserver(
        promoOffer,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            isInViewport.value = true
            stop()
          }
        },
        { threshold: 0.15 }
      )
    })

    const track = () => {
      $dataLayer.linkClick({
        category: 'promo',
        action: props.data.name
      })
    }

    const hasImage = computed<boolean>(() => {
      const { promotionImages } = props.data

      if (!promotionImages) return false

      return (
        (promotionImages.desktopImage?.file?.url?.length || 0) > 1 &&
        (promotionImages.mobileImage?.file?.url?.length || 0) > 1 &&
        (promotionImages.tabletImage?.file?.url?.length || 0) > 1
      )
    })

    const isCurrentFinanceOffer = computed<boolean>(() => {
      if (!props.data.primaryCtaUrl) return false

      try {
        const url = new URL(props.data.primaryCtaUrl)
        const financeParams = new URLSearchParams(url.search).get('finance')
        const deepLink = financeCriteriaStore.deeplink

        if (!deepLink || !financeParams) return false

        return deepLink === financeParams
      } catch (e) {
        return false
      }
    })

    return {
      applyFilters,
      hasImage,
      isCurrentFinanceOffer,
      isInViewport,
      promoOffer,
      track
    }
  }
}
</script>

<style lang="scss">
.promoOffer {
  color: #fff;
  border-radius: 4px;
}

.promoOffer__container {
  background-color: #000;
  display: grid;
  border-radius: 4px;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    'copy'
    'button';
  overflow: hidden;
  padding: rem(16);
  overflow: hidden;
  opacity: 0;

  @include viewport('md') {
    grid-template-columns: 70% 30%;
    grid-template-areas: 'copy button';
  }

  &--isShown {
    opacity: 1;
    transform: none;
    animation-name: fadeDropIn;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(0.19, 1, 0.56, 1);
  }
}

.promoOffer__container__copy {
  grid-area: copy;

  h2 {
    margin-bottom: rem(8);
    word-break: break-word;
  }
}

.promoOffer__container__button {
  grid-area: button;
  margin-top: rem(16);

  .os-button {
    word-break: break-word;
    white-space: normal;
  }

  @include viewport('md') {
    margin-top: 0;
    margin-left: auto;
  }
}

.promoOffer--hasImage {
  .promoOffer__container {
    padding: 0;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'image'
      'copy'
      'button';

    @include viewport('sm') {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        'copy image'
        'button image';
    }
  }

  .promoOffer__container__copy {
    margin: rem(16);

    h2 {
      font-family: $mb-font-text-bold;
      font-size: rem(16);
      @include viewport('sm') {
        font-family: $mb-font-title;
        font-size: rem(24);
      }
      @include viewport('md') {
        font-family: $mb-font-title;
        font-size: rem(30);
      }
      @include viewport('lg') {
        font-size: rem(40);
      }
    }
  }

  .promoOffer__container__button {
    margin: 0 rem(16) rem(16) rem(16);
    display: flex;
    align-items: flex-end;
  }

  .promoOffer__container__image {
    grid-area: image;
    position: relative;
    background-color: $grey--lightest;

    picture {
      height: 100%;
      display: flex;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.promoOffer__container--isShown {
  opacity: 1;
  transform: none;
  animation-name: fadeDropIn;
  animation-duration: 0.8s;
  animation-timing-function: $cubicEasing;
}
</style>
