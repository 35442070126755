<template>
  <OsVideoPlayer
    :url="videoData.file.file.url"
    :poster="poster"
    :autoplay="videoData.autoplay"
    :controls="videoData.controls"
    :loop="videoData.loop"
    :muted="videoData.muted"
    :preload="videoData.preload"
    :description="videoData.file.description"
    :content-type="videoData.file.file.contentType"
  ></OsVideoPlayer>
</template>

<script lang="ts">
import { CmsVideoItem, MediaItem } from '~/types/cms/contentModel'

export default {
  name: 'VideoPlayer',
  props: {
    mediaItem: {
      type: Object as PropType<MediaItem>,
      required: true
    },
    isViewportDesktop: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const videoData = computed<CmsVideoItem>(() => {
      return props.isViewportDesktop
        ? (props.mediaItem.desktop as CmsVideoItem)
        : (props.mediaItem.mobile as CmsVideoItem)
    })

    const poster = computed(
      () => `${videoData.value.poster?.file.file.url}?w=1280` || ''
    )

    return {
      videoData,
      poster
    }
  }
}
</script>
<style lang="scss" scoped></style>
