import { useFinanceProductsStore } from '~/stores/finance/productsStore'
import {
  EFinanceServices,
  useServiceStatusStore
} from '~/stores/serviceStatusStore'

export async function financeRetry() {
  // Retry should be client-side only
  if (import.meta.server) return

  const { $pinia } = useNuxtApp()

  const financeProductsStore = useFinanceProductsStore($pinia)
  const serviceStatusStore = useServiceStatusStore($pinia)

  // Flag the Finance Calculate endpoint for retry
  if (!serviceStatusStore.finance.calculate.isEnabled) {
    serviceStatusStore.updateFinanceService(EFinanceServices.Calculate, true)
  }

  // Flag the Finance Vin Defaults endpoint for retry
  if (!serviceStatusStore.finance.vinDefaults.isEnabled) {
    serviceStatusStore.updateFinanceService(EFinanceServices.VinDefaults, true)
  }

  // Retry the Finance Defaults endpoint if it's previously failed
  if (!serviceStatusStore.finance.defaults.isEnabled) {
    await financeProductsStore.fetchAllProducts()
  }
}
