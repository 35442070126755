<template>
  <picture
    class="image"
    :class="[
      {
        'image--loaded': !isLoading,
        'image--error': error
      }
    ]"
    v-bind="$attrs"
  >
    <template v-if="mobileImage">
      <source
        v-for="(source, key) in mobileImage"
        :key="`m-${key}`"
        :media="`(max-width: ${breakPoint - 1}px) and (orientation: portrait)`"
        :srcset="source.image.srcset"
        :sizes="source.image.sizes"
        :loading="loading"
      />
    </template>
    <template v-if="desktopImage">
      <source
        v-for="(source, key) in desktopImage"
        :key="`d-${key}`"
        :media="
          mobileImage
            ? `(min-width: ${breakPoint}px), (orientation: landscape)`
            : undefined
        "
        :srcset="source.image.srcset"
        :sizes="source.image.sizes"
        :loading="loading"
      />
    </template>
    <img
      v-if="desktopImage"
      ref="imgEl"
      :src="desktopImage[1].image.src"
      :loading="loading"
      :width="dimentions.width"
      :height="dimentions.height"
      :alt="imageData.desktop?.title"
      @onload="onload"
      @onerror="onerror"
    />
  </picture>
</template>

<script lang="ts">
import { ImageSizes } from '@nuxt/image'
import { PropType } from 'nuxt/dist/app/compat/capi'
import { IContentfulImageSet } from '~/types/image'

export interface IResponsiveImageFormats {
  format: string
  image: ImageSizes
}

export default {
  name: 'ImageResponsive',
  props: {
    imageData: {
      type: Object as PropType<IContentfulImageSet>,
      required: true
    },
    sizes: {
      type: Array as PropType<string[]>,
      default: () => ['xs:100vw lg:1280', 'sm:100vw  lg:1280'],
      required: false
    },
    breakPoint: {
      type: Number,
      required: false,
      default: 850
    },
    loading: {
      type: String as PropType<'lazy' | 'eager'>,
      required: false,
      default: 'lazy'
    }
  },
  setup(props: any) {
    const isLoading = ref(true)
    const error = ref(false)

    const onload = () => {
      isLoading.value = false
    }

    const onerror = () => {
      isLoading.value = false
      error.value = true
    }

    const imgEl = ref<HTMLImageElement>()
    // Returns default image size options based on breakpoint.
    const dimentions = computed(() => {
      const { isViewportDesktop } = useUi()
      const key = isViewportDesktop.value ? 'desktop' : 'mobile'
      const w = props.imageData[key]?.file.details.image.width
      const h = props.imageData[key]?.file.details.image.height
      const r = h / w
      return {
        width: key === 'desktop' ? 1280 : 1024,
        height: key === 'desktop' ? r * 1280 : r * 1024,
        r
      }
    })

    const img = useImage()

    const imageAtBreakPoint = (
      breakpoint: string
    ): IResponsiveImageFormats[] | null => {
      if (!props.imageData[breakpoint]) {
        return null
      }

      return ['webp', 'jpg'].map((format) => {
        return {
          format,
          image: img.getSizes(props.imageData[breakpoint].file.url, {
            sizes: props.sizes[breakpoint === 'desktop' ? 1 : 0],
            provider: 'contentful',
            densities: 'x1 x2',
            modifiers: {
              format,
              quality: 70,
              width: Math.min(
                1280,
                props.imageData[breakpoint].file.details.image.width
              )
            }
          })
        }
      })
    }

    const desktopImage = computed<IResponsiveImageFormats[] | null>(() =>
      imageAtBreakPoint('desktop')
    )
    const mobileImage = computed<IResponsiveImageFormats[] | null>(() =>
      imageAtBreakPoint('mobile')
    )

    const nuxtApp = useNuxtApp()
    const initialLoad = nuxtApp.isHydrating
    onMounted(() => {
      if (!imgEl.value) {
        return
      }

      if (imgEl.value.complete && initialLoad) {
        if (imgEl.value.naturalWidth === 0) {
          onerror()
        } else {
          onload()
        }
      }
      imgEl.value.onload = () => {
        onload()
      }
      imgEl.value.onerror = () => {
        onerror()
      }
    })

    return {
      img,
      imgEl,
      mobileImage,
      desktopImage,
      dimentions,
      onerror,
      onload,
      isLoading,
      error
    }
  }
}
</script>
