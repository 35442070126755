<template>
  <section class="home-hero" :class="{ 'home-hero--dark': isDark }">
    <OsPageHeader>
      <template v-if="!hasSubheading" #pretitle>
        Mercedes-Benz Online Showroom
      </template>
      <template v-else #subtitle>{{ subHeading }}</template>
      <template #title>
        {{
          isKioskMode ? retailer : data.content?.heading || data.content?.title
        }}
      </template>
      <template #navigation>
        <BadgeNavigation
          :items="ctas"
          :badge-props="{ dark: true }"
          @item-selected="scrollTo($event, 'top nav')"
        />
      </template>
    </OsPageHeader>

    <figure v-if="data.content" class="home-hero__img">
      <BadgeItem v-if="data.content.isOffer" floating>Offer</BadgeItem>

      <component
        :is="isButton ? 'button' : 'div'"
        :aria-label="isButton ? data?.content.subheading : ''"
        @click="isButton ? goTo() : null"
      >
        <OsVideoResponsive
          v-if="data?.content?.mediaItems?.type === 'video'"
          :media-item="data?.content?.mediaItems"
          :is-viewport-desktop="isViewportDesktop"
        ></OsVideoResponsive>
        <ImageResponsive
          v-else
          loading="eager"
          :image-data="imageData"
        ></ImageResponsive>
      </component>
      <figcaption
        v-if="data.seoDescription"
        :class="[isDark ? 'text-gray-900' : 'text-white']"
        class="home-hero__seo-description"
      >
        {{ data.seoDescription || '' }}
      </figcaption>
    </figure>
  </section>
</template>

<script lang="ts">
import { IHeroData, ScrollableBadge } from '~/types/components'
import { IContentfulImageSet } from '~/types/image'
import { ECmsSectionType } from '~/types/cms/contentModelEnum'
import { useFiltersStore } from '~/stores/filtersStore'
import { ContentfulMedia } from '~/types/cms/contentfulModel'

export default {
  props: {
    data: {
      type: Object as PropType<IHeroData>,
      required: true
    }
  },
  setup(props) {
    const { scrollTo } = useScroll()

    const { openURL } = useOpenURL()
    const { $dataLayer } = useNuxtApp()
    const { isViewportDesktop } = useUi()
    const isKioskMode = useState('kioskmode')

    const retailer = computed(() => {
      const filtersStore = useFiltersStore()
      return filtersStore.active?.retailerName || 'Mercedes-Benz'
    })

    const imageData = computed<IContentfulImageSet>(() => {
      return {
        desktop: props.data?.content?.mediaItems?.desktop
          .file as ContentfulMedia,
        mobile: props.data?.content?.mediaItems?.mobile.file as ContentfulMedia
      }
    })

    const subHeading = computed(() => props.data?.content?.subheading)
    const hasSubheading = computed(
      () => !!props.data?.content?.subheading?.length
    )
    const isDark = computed<boolean>(() => !props.data?.content?.useLightTheme)

    const heroButtons: ScrollableBadge[] = [
      {
        key: ECmsSectionType.BUDGET_SEARCH,
        analyticName: 'budget',
        text: 'Budget'
      },
      {
        key: ECmsSectionType.MODEL_SEARCH,
        analyticName: 'model',
        text: 'Model'
      },
      {
        key: ECmsSectionType.REFINED_SEARCH,
        analyticName: 'refine',
        text: 'Explore'
      },
      {
        key: ECmsSectionType.CURATED_SEARCH,
        analyticName: 'inspire',
        text: 'Inspire'
      },
      {
        key: ECmsSectionType.HOW_IT_WORKS,
        analyticName: 'how  works',
        text: 'How it works'
      },
      {
        key: ECmsSectionType.OFFERS,
        analyticName: 'offers',
        text: 'Offers'
      },
      {
        key: ECmsSectionType.SIMILAR_MODELS,
        analyticName: 'similarModels',
        text: 'Similar models'
      },
      {
        key: ECmsSectionType.INTERIOR_360,
        analyticName: 'Interior',
        text: 'Interior'
      },
      {
        key: ECmsSectionType.ACTION_BANNER,
        analyticName: 'Notify me',
        text: 'Notify me'
      },
      {
        key: ECmsSectionType.SPOTLIGHT_CARS,
        analyticName: 'selected models',
        text: 'Selected models'
      }
    ]

    const ctas = computed<ScrollableBadge[]>(() =>
      [
        ...new Set(
          props.data.sections?.filter((section) =>
            heroButtons.find(({ key }) => key === section.key)
          )
        )
      ].map((sectionType) => {
        const button = heroButtons.find(({ key }) => key === sectionType.key)!

        return {
          ...button,
          key: useCamelCase(sectionType.navigationLabel || button.key),
          text: sectionType.navigationLabel || button.text
        }
      })
    )

    const isButton = computed(() => !!props.data?.content?.targetUrl)

    const goTo = () => {
      if (!props.data?.content?.targetUrl) return
      const { isBlank, targetUrl } = props.data?.content
      openURL(isBlank, targetUrl)

      $dataLayer.linkClick({
        category: 'promo',
        action: props.data?.content?.title || ''
      })
    }

    return {
      ctas,
      isDark,
      scrollTo,
      goTo,
      subHeading,
      hasSubheading,
      isKioskMode,
      retailer,
      isButton,
      isViewportDesktop,
      imageData
    }
  }
}
</script>

<style lang="scss" scoped>
.home-hero {
  margin-top: 0;
  padding-top: rem(40);

  .home-hero__img {
    border-radius: 6px;
    position: relative;

    button {
      cursor: pointer;
      padding: 0;
      text-align: left;
      width: 100%;
    }

    .home-hero__seo-description {
      position: absolute;
      bottom: 0;
      font-size: rem(14);
      padding: rem(8) rem(16);
      color: $white;

      @include viewport('md', 'max-width') {
        &--relative {
          color: $grey--darkest;
          position: relative;
          padding: rem(8) 0 0 0;
        }
      }
    }

    &:deep(.image) {
      border-radius: rem(4);
    }
    &:deep(img) {
      object-fit: cover;
      object-position: right;

      @include viewport('xl') {
        object-position: unset;
      }
    }
  }
}
</style>
