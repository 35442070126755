import { IKioskModeCookie } from '~/types/cookies'

// Hanlde URLS
export const useOpenURL = () => {
  const config = useRuntimeConfig()
  const { $bus } = useNuxtApp()
  const { push } = useRouter()
  /**
   * Handle opening URLS, external links alway open in new window
   * Relative URL can push or open in new window with openInNewWindow
   *
   * @param {any} openInNewWindow:boolean
   * @param {any} targetUrl:string=''
   * @returns {any}
   */
  const openURL = (
    openInNewWindow: boolean = false,
    targetUrl: string = ''
  ) => {
    const isRelative = targetUrl?.startsWith('/')
    const url =
      isRelative && openInNewWindow
        ? `${config.public.baseUrl}${targetUrl}`
        : targetUrl || ''

    const isExternal = !isRelative && !url.startsWith(config.public.baseUrl)

    if (openInNewWindow || isExternal) {
      openWindow(url, undefined)
    } else {
      push(url)
    }
  }

  /**
   * Handle opening Window - If in kiosk mode we emit an event
   * @param {any} url:string
   * @param {any} target:string=''
   * @returns {void}
   */
  const openWindow = (url: string, target: string = ''): void => {
    const kioskModeCookie = useCookie<IKioskModeCookie | null>('kioskmode')

    if (kioskModeCookie.value?.isKiosk) {
      $bus.emit('qrcode:trap-link', { url })
    } else {
      window.open(url, target)
    }
  }

  return {
    openURL,
    openWindow
  }
}
