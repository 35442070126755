<template>
  <client-only>
    <div class="award">
      <template
        v-for="(imageData, index) in logos.slice(0, 4)"
        :key="`award_${index}`"
      >
        <div class="award__image">
          <ImagePicture
            provider="contentful"
            :width="104"
            :height="82"
            sizes="xs:104px"
            :alt="`${imageData.title}`"
            :src="imageData.url"
            loading="lazy"
            format="webp,png"
          />
        </div>
        <div class="award__spacer"></div>
      </template>
    </div>
  </client-only>
</template>

<script lang="ts">
import { IAwardImage } from '~/types/components'

export default {
  props: {
    logos: {
      type: Array as PropType<IAwardImage[]>,
      required: false,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.award {
  text-align: center;
  position: relative;
  color: white;

  &:before {
    content: ' ';
    border-bottom: 1px solid $grey--light;
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    z-index: -1;
  }

  & > * {
    display: inline-block;
  }

  .award__spacer {
    width: rem(16);

    @include viewport('md') {
      width: rem(24);
    }
  }

  .award__image {
    background: currentColor;
    padding: 0 rem(16);

    @include viewport('md') {
      padding: 0 rem(24);
    }

    img {
      width: 71px;
      height: 56px;

      @include viewport('md') {
        width: 104px;
        height: 82px;
      }
    }
  }
}
</style>
