<template>
  <section class="homepage">
    <client-only v-if="isKioskMode">
      <Teleport to="#os-header__qr">
        <OsQRCode v-if="isKioskMode" />
      </Teleport>
    </client-only>
    <transition name="fade">
      <section v-if="!homeLoading" class="homepage__container">
        <HomeHero
          v-if="heroData"
          :data="heroData"
          class="homepage__container__hero"
        />
        <section v-if="hasAward" class="no-border award__section">
          <OsAwards :logos="awardData" />
        </section>

        <section
          v-for="(section, index) in dynamicSections"
          :id="useCamelCase(section.props.navigationLabel || section.key)"
          :key="`${section.key}-${index}`"
          :class="{ 'no-border': section.key === ECmsSectionType.HOW_IT_WORKS }"
        >
          <component :is="section.component" v-bind="section.props" />
        </section>
      </section>
    </transition>
  </section>
</template>

<script setup lang="ts">
import {
  OfferPromo,
  LazyHomeBudgetPrefilter,
  LazyHomeRefinedSearchBanner,
  LazyHomeCuratedSearch,
  LazyHomeHowItWorks,
  LazyHomeCmsCardGrid,
  LazyHomeActionBanner,
  LazyHomeModelSearch,
  LazyHomeContentBlockGrid,
  LazyHomeInteriorSection,
  LazyHomeSpotlightCars
} from '#components'

import { IHeroData, ISectionComponent, IAwardImage } from '~/types/components'
import { ECmsSectionType } from '~/types/cms/contentModelEnum'
import { EConditionSlug } from '~/types/vehicle'
import { ERouteConditions, ERoutes } from '~/types/routes'
import { useSettingsStore } from '~/stores/settingsStore'
import { useFiltersStore } from '~/stores/filtersStore'

definePageMeta({
  name: ERoutes.Homepage,
  middleware: 'condition'
})

const isKioskMode = useState('kioskmode')

const { $api, $dataLayer, $bus } = useNuxtApp()
const route = useRoute()
const filtersStore = useFiltersStore()
const settingsStore = useSettingsStore()

const { data: home, pending: homeLoading } = useCustomLazyAsyncData(
  'home',
  () =>
    $api.pages.getHome(
      (route.params?.slug as string) || '',
      route.params.condition as ERouteConditions
    )
)

// TODO: This should not return undefined
const { data: promo } = useCustomLazyAsyncData(
  'promo',
  async () => await $api.pages.getPromotions()
)

// TODO: Is this needed?
financeRetry()

// Meta
useCmsSeo(home)

const { initOneTag } = useFlashTalking()

// analytics
function analytics() {
  $dataLayer.pageView({
    vehicle: {
      condition: route.params.condition
    }
  })

  initOneTag()
}

const isMounted = ref(false)

watch([isMounted, homeLoading], () => {
  if (isMounted.value && !homeLoading.value) {
    settingsStore.updateFooter(footerData.value[0])
  }
})

onMounted(() => {
  isMounted.value = true

  if (process.client) {
    // todo: is this the same than -> window.onNuxtReady(analytics) ??
    analytics()
  }

  $bus.on('data-layer:page-view', analytics)
})

useBannerComposition(ERoutes.Homepage)

onUnmounted(() => {
  $bus.off('data-layer:page-view')
})

const isNew = computed(() => route.params.condition === EConditionSlug.New)

/**
 * Returns Kiosk Footer Data
 */
const footerData = computed(() => {
  const footerSection = (home.value?.sections || []).find(({ sectionType }) => {
    return sectionType === ECmsSectionType.FOOTER_IMAGE
  })
  return (footerSection?.content || []).map((contentfulFile: any) => {
    return {
      image: contentfulFile.desktop.file.url,
      message: footerSection?.body
    }
  })
})

/**
 * Returns component list excluding promooffer and hero data.
 * to build the anchore CTAs in the home hero.
 */
const heroData = computed((): IHeroData => {
  const heroSection = findHomeSection(
    home.value?.sections || [],
    ECmsSectionType.HERO_IMAGES
  )

  return {
    content: heroSection?.content?.[0] || null,
    sections: dynamicSections.value.map(({ key, props }) => {
      return {
        key,
        navigationLabel: props.navigationLabel || ''
      }
    }),
    seoDescription: home.value?.seoDescription
  }
})

const hasAward = computed(() => isNew.value && awardData.value.length)

// Returns the Award images
const awardData = computed((): IAwardImage[] => {
  const awardSection = findHomeSection(
    home.value?.sections || [],
    ECmsSectionType.AWARD
  )

  if (!awardSection) return []

  return (awardSection.content || [])?.map((awardContent) => {
    return {
      title: awardContent.image.title,
      url: awardContent.image.file.url,
      width: awardContent.image.file.details.image.width,
      height: awardContent.image.file.details.image.height
    }
  })
})

/**
 *  Returns sorted list of ISectionComponent based on order returned from CMS
 */
const dynamicSections = computed((): ISectionComponent[] => {
  if (homeLoading.value) return []

  const exludedSections = [
    ECmsSectionType.HERO_IMAGES,
    ECmsSectionType.FOOTER_IMAGE,
    ...(!promo.value ? [ECmsSectionType.PROMO_OFFER] : []),
    ...(!filtersStore.retailer ? [ECmsSectionType.SPOTLIGHT_CARS] : [])
  ]

  const popularModelsSection = findHomeSection(
    home.value?.sections || [],
    ECmsSectionType.POPULAR_MODELS
  )

  const homeSections = (home.value?.sections || [])
    .filter(
      ({ sectionType }) =>
        !exludedSections.includes(sectionType) &&
        sections.value.find(({ key }) => key === sectionType)
    )
    .map((section) => {
      const baseSection = sections.value.find(
        ({ key }) => key === section.sectionType
      )!

      let restrictedModels: number[] = []
      let popularModels: string[] = []
      let isTevOnly = false

      if (section.sectionType === ECmsSectionType.MODEL_SEARCH) {
        isTevOnly = home.value?.isTEVModel || false
        restrictedModels = route.params.slug
          ? (home.value?.modelIds || []).map((x) => parseInt(x))
          : []

        if (popularModelsSection) {
          popularModels = popularModelsSection.content?.[0].modelIds || []
        }
      }

      const sectionContent =
        section.sectionType === ECmsSectionType.PROMO_OFFER
          ? promo.value
          : section.content

      return {
        ...baseSection,
        props: {
          data: sectionContent || null,
          title: section.sectionName,
          body: section.body,
          sectionType: section.sectionType,
          navigationLabel: section.navigationLabel,
          restrictedModels,
          popularModels,
          isTevOnly,
          isNew: isNew.value
        }
      }
    })

  return [...homeSections]
})

/**
 *  Available components for dynamic homepage
 */
const sections = computed(
  (): (Omit<ISectionComponent, 'props'> & {
    props: Partial<ISectionComponent['props']>
  })[] => {
    return [
      {
        key: ECmsSectionType.HERO_IMAGES,
        component: 'HomeHero',
        props: {}
      },

      {
        key: ECmsSectionType.PROMO_OFFER,
        component: OfferPromo,
        props: {}
      },

      {
        key: ECmsSectionType.BUDGET_SEARCH,
        component: LazyHomeBudgetPrefilter,
        props: {}
      },
      {
        key: ECmsSectionType.MODEL_SEARCH,
        component: LazyHomeModelSearch,
        props: {}
      },
      {
        key: ECmsSectionType.REFINED_SEARCH,
        component: LazyHomeRefinedSearchBanner,
        props: {}
      },
      {
        key: ECmsSectionType.CURATED_SEARCH,
        component: LazyHomeCuratedSearch,
        props: {}
      },
      {
        key: ECmsSectionType.HOW_IT_WORKS,
        component: LazyHomeHowItWorks,
        props: {}
      },
      {
        key: ECmsSectionType.PAGES_CONTENT_BLOCK,
        component: LazyHomeContentBlockGrid,
        props: {}
      },
      {
        key: ECmsSectionType.OFFERS,
        component: LazyHomeCmsCardGrid,
        props: {}
      },
      {
        key: ECmsSectionType.TRENDING,
        component: LazyHomeCmsCardGrid,
        props: {}
      },
      {
        key: ECmsSectionType.SIMILAR_MODELS,
        component: LazyHomeCmsCardGrid,
        props: {}
      },
      {
        key: ECmsSectionType.ACTION_BANNER,
        component: LazyHomeActionBanner,
        props: {}
      },
      {
        key: ECmsSectionType.CALCULATORS,
        component: LazyHomeCmsCardGrid,
        props: {}
      },
      {
        key: ECmsSectionType.INTERIOR_360,
        component: LazyHomeInteriorSection,
        props: {}
      },
      {
        key: ECmsSectionType.SPOTLIGHT_CARS,
        component: LazyHomeSpotlightCars,
        props: {}
      }
    ]
  }
)
</script>

<style lang="scss" scoped>
.homepage {
  position: relative;
}
.homepage__container__hero {
  opacity: 1;
  transition: opacity 0.5s;
  &--loading {
    opacity: 0;
  }
}

.homepage__container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: rem(104);
  overflow: hidden;
  @include componentHorizontalPadding();

  &:deep(> section) {
    max-width: $containerWidth;
    width: 100%;
    margin: 0 auto;

    &:not(:first-child) {
      margin-top: rem(88);
      padding-top: rem(88);
      border-top: 1px solid $grey--light;

      @include viewport('md', 'max-width') {
        margin: rem(40) auto 0;
        padding: rem(40) 0 0;
      }
    }

    &.award__section {
      margin-top: 0;

      & + section {
        border-top: none;
      }
    }

    &:first-child,
    &.no-border {
      border-top: 0;
    }
  }
}
</style>
